body {
  margin: 0;
  overflow-x: auto;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-group{
  position: relative;
  text-align: center!important;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem!important;
  margin-bottom: 1.5rem!important;
  color: white;
  background-color: black;
}

.form-control {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 42px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: 600;
  color: white;
  margin: 1rem 1rem;
}

.home {
  text-align: center;
  width:100%;
}

.about {
  text-align: center;
  width:100%;
}

.main-content {
  padding-left: 20px;
  padding-right: 20px;
}

.tsparticles {
  width: inherit;
  height: inherit;
}

.banner {
  padding: 15px 15px;
}

.mini-banner {
  padding: 20px 20px;
}

.particles-back {
  z-index: -1;
  position: absolute;
}

.library-banner {
  padding: 75px 75px;
  height: 100%;
}

.baron-preview {
  padding: 10px 10px;
  margin: 5px 0;
}

.baron-preview a {
  text-decoration: none;
  color: white;
  text-align: center;
}

.baron-preview:hover {
  box-shadow: 6px 8px 42px rgba(176,244,254,1);
}

.baron-details {
  margin: 5px 5px;
}

.baron-preview h6 {
  font-weight: 600;
}

.baron-preview p {
  margin: 0px 0;
}

.baron-preview img {
  margin: 0px 0;
}

.explore-list {
  display: grid;
  width: 98%;
  grid-auto-flow: row;
  grid-template-columns: repeat(5,1fr);
  text-decoration: none;
  padding-bottom: 42px;
}

.mint-info {
  display: block;
  width:100%;
  margin: 0px 0;
  padding: 0px 10px;
}

.mint-list {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-template-columns: repeat(1,1fr);
  text-decoration: none;
  padding-bottom: 42px;
}

.explore {
  display: block;
  width:100%;
  justify-content: center;
  margin: 0px 0;
  padding: 0px 10px;
}

.baron-page {
  display: block;
  width:100%;
  margin: 15px 0;
}

.baron-page h4 {
  margin: 10px 0px 0;
}

.rarity {
  display: block;
  width:100%;
  margin: 0px 0;
}

.rarity-title {
  display: block;
  width:100%;
  margin: 0px 0;
}

.rarity-list {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-template-columns: repeat(3,1fr);
}

.rarity-preview {
  padding: 10px 10px;
  margin: 5px 0;
  max-width: 250px;
}

.rarity-preview h6 {
  max-width: inherit;
  margin: 5px 0;
  padding: 0px 0px;
}

.rarity-preview .list-items{
  justify-content: left;
}

.list-items {
  width: inherit;
}

.list-items span{
  font-weight: 600;
  width: inherit;
  margin: 5px 0;
  padding: 0px 0px;
  font-size: calc(5px + 2vmin);
}

.blue {
  font-weight: 600;
  color: rgba(176,244,254,1);
}

.zoom-within-container {
  height: 500px; /* [1.1] Set it as per your need */
  overflow: visible; /* [1.2] Hide the overflowing of child elements */
  margin:0px 0px;
  padding-bottom: 55px;
}
.zoom-within-container img {
  transition: transform .5s ease;
}
.zoom-within-container:hover img {
  transform: scale(1.5);
}

.zoom-within-container-pay {
  height: 250px; /* [1.1] Set it as per your need */
  overflow: visible; /* [1.2] Hide the overflowing of child elements */
  margin:0px 0px;
}
.zoom-within-container-pay img {
  transition: transform .5s ease;
}
.zoom-within-container-pay:hover img {
  transform: scale(1.5);
}

.extra-pad {
  padding-top: 15px;
}

.each-fade {
  overflow-x: scroll;
}

.button {
  /* background-color: rgba(255,255,255,1); */
  background-color: rgba(33,37,43,1);
  text-align: center;
  height: 2.75rem;
  /* color: "#21252b"; */
  color: rgba(176,244,254,1);
  font-family: 'Nunito';
  font-weight: 600;
  box-sizing: "border-box";
  border-radius: 12px;
  box-shadow: "6px 6px 0 0 #B0F4FE";
  transition: "backgroun .3s, border-color .3s, color .3s";
  text-decoration: none;
  display: inline-block;
}

.button:hover {
  background-color: rgba(176,244,254,1);
  color: rgba(33,37,43,1);
}

.button:active {
  background-color: rgba(176,244,254,1);
  color: rgba(33,37,43,1);
}

.button:focus {
  background-color: rgba(176,244,254,1);
  color: rgba(33,37,43,1);
}

.button:visited {
  background-color: rgba(176,244,254,1);
  color: rgba(33,37,43,1);
}